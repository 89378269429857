import { useDeepCompareEffect, useMemoizedFn, useRequest } from "ahooks";
import constate from "constate"
import { useState } from "react"
import { getVehicle } from "../api/vehicle.api";
import { useVehicleList } from "./vehicleList.hook";
import { useGlobalScope } from "./globalScope.hook";

export interface IVinType {
    vinId: string;
    shop: string;
    city?: string;
    utm_zone?: string;
}

const useCurrentVinInside = () => {
    const SCOPE_KEY_VIN = `current_vehicleId`;
    const { vehicleIds } = useVehicleList();
    const { innerShop } = useGlobalScope();
    const [vehicleId, setVehicleId] = useState<number | undefined>();
    const { data } = useRequest(() => getVehicle(innerShop?.innerShopId, vehicleId), { pollingInterval: 5000, refreshDeps: [vehicleId, innerShop?.innerShopId] })

    const setVinHandler = useMemoizedFn((vehicleId?: number) => {
        if (vehicleId !== undefined) {
            localStorage.setItem(SCOPE_KEY_VIN, JSON.stringify(vehicleId))
        } else {
            localStorage.removeItem(SCOPE_KEY_VIN)
        }
        setVehicleId(vehicleId)
    })

    useDeepCompareEffect(() => {
        const vehicleId = localStorage.getItem(SCOPE_KEY_VIN);

        if (vehicleId && vehicleIds.length > 0) {
            const vinId = JSON.parse(vehicleId);
            if (vehicleIds.includes(vinId)) {
                setVehicleId(vinId);
                return;
            }
        }
        
        if (vehicleIds.length > 0) {
            setVehicleId(vehicleIds[0]);
        }

    }, [vehicleIds])

    return {
        vehicleId, switchVehicle: setVinHandler, vinStr: data?.vehicle?.name, vehicle: data?.vehicle, travelStations: data?.travelStations, currentTarget: data?.currentTarget, createTime: data?.createTime, points: data?.points
    }
}

export const [CurrentVinProvider, useCurrentVin] = constate(useCurrentVinInside);